.contact {
    max-width: 1344px;
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    margin-bottom: 120px
}

.contact .left,
.contact .right {
    width: 48.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.contact .left .map {
    border: 1px solid #ccc;
    border-radius: 25px;
    outline: none;
}

.contact .right h1 {
    width: 100%;
    text-align: center;
    margin: 0;
}

.contact .right p {
    width: 100%;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
}

.contact .right .form {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 50px;
    border-radius: 25px;
    border: 1px solid #ccc;
    gap: 10px;
}

.contact .right .loader-form {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 50px;
    border-radius: 25px;
    border: 1px solid #ccc;
    gap: 50px;  
}

.contact .right .form input,
.contact .right .form textarea {
    width: calc(99% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.contact .right .form input {
    height: 25px
}

.contact .right .form textarea {
    resize: none !important;
    height: 65px
}

.contact .right .form button {
    width: 100%;
    height: 40px;
    margin:  0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #333;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
}

.contact .right .form button {
    position: relative;
    display: inline-block;
}

.contact .right .form button .tooltiptext {
    visibility: hidden;
    width: 100%;
    left: 0px;
    top: 45px;
    background-color: #428bca90;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
 
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.contact .right .form button:disabled:hover .tooltiptext {
    visibility: visible;
}

.contact .right .form button:disabled {
    background-color: gray;
    cursor: default;
}

/* MEDIA QUERIES  */
/* contact-media */

@media (max-width: 1600px ) {
    .contact {
        width: calc(100% - 240px)
    }
}

@media (max-width: 1266px) {
    .contact .right .form h3 { 
        text-align: center;
        width: 230px
    }
}

@media (max-width: 1150px) {
    .contact {
        width: calc(100% - 120px);
    }
}

@media (max-width: 900px) {
    .contact {
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        gap: 20px;
        height: auto
    }

    .contact .left,
    .contact .right {
        width: 100%;
        height: 450px;
    }

    .contact .right .form h3 { 
        text-align: center;
        width: 100%
    }
}

@media (max-width: 820px) {
   .contact {
    width: calc(100% - 30px);
   } 
}

@media (max-width: 550px) {
    .contact .right .form {
        width: calc(100% - 40px);
        padding: 50px 20px
    }

    .contact .right .form textarea {
        min-height: 30px
    }
}