.alert-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 20px 20px 20px 15px;
    position: fixed;
    top: -140px;
    z-index: 9;
    border: 1px solid lightgray;
    width: auto;
    min-width: 300px;
    height: 40px;
    border-radius: 15px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.alert-container .icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    stroke-width: 2px;
    width: 60px;
}

.alert-container .positive-icon {
    color: green;
}

.alert-container .neutral-icon {
    color: #428bca;
}

.alert-container .negative-icon {
    color: red;
}

#show-alert {
    animation: slideIn 1s forwards
}

#hide-alert {
    animation: slideOut 1s forwards
}

@keyframes slideIn {
    0% {
        transform : translateY(0px);
    }
    100% {
        transform : translateY(245px);
    }
    
}

@keyframes slideOut {
    0% {
        transform : translateY(245px);
    }
    100% {
        transform : translateY(0px);
    }
}