.home {
    width: calc(100%);
    height: calc(100vh - 61px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home .cover {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    border-radius: 25px;
    background-size: cover;   
}

.home .cover .left,
.home .cover .right {
    height: 100%;
    padding-bottom: 100px;
}

.home .cover .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    z-index: 1;
    padding-left: 120px;
    box-shadow: 20px 0px 14px -7px white;
}

.home .cover h1 {
    width: auto;
    text-align: left;
    font-size: 3.5rem;
    font-weight: 700;
    white-space: nowrap;
    color: #333;
    margin: 0;
}

.home .cover .innovation-text {
    color: #333;
    font-size: 6rem;
    margin: 0;
}

.home .cover h2 {
    font-size: 2rem;
    font-weight: 600;
    white-space: nowrap;
    color: #333;
}

.home .cover .left .list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 18px;
}

.home .cover .left .check-icon {
    width: 25px; 
    height:  25px;
    stroke-width: 2px;
}

.home .cover .left button {
    border-radius: 15px;
    outline: none;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.3rem;
    background-color: #418bca;
    margin-top: 30px;
    width: 300px
}

.home .cover .left button:hover {
    animation : shadowButtonHover 0.5s forwards;
    cursor: pointer;
}

.home .cover .right {
    width: 50%;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-image: url('../assets/endless-constellation.svg');
}

.home .cover .right .card {
    background-color: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
     width: 500px;
    height: 312.5px;
    border-radius: 15px;
    position: relative;
    animation: floating 3s infinite;
   

}

.home .cover .right .hardware-card {
     background-image: url('../assets/pcb.svg');
    background-size: contain;
    background-repeat: no-repeat;
    top: 10%;
    z-index: 1;
    rotate: -15deg;   
}


.home .cover .right .software-card {
    top: 10%;
    right: -34%;
    z-index: 2;
    rotate: 15deg;
    background-image: url('../assets/dashboard.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

@keyframes shadowButtonHover {
    0% {
        box-shadow: none;
    }

    100% {
        box-shadow: rgba(65, 139, 202, 0.75) 0px 5px 15px;
    }
    
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }    
}

@keyframes textGlowPulse {
   0% {
    text-shadow: 0px 0px 20px #fee599;
   } 

   50% {
    text-shadow: none;
   }

   100% {
    text-shadow: 0px 0px 20px #fee599;
   } 
}


/* Responsiveness Media Queries */

@media (max-width: 1645px) {
    .home .cover .right .card {
        top: 15%;
        width: 450px;
        height: 281.25px
    } 


}

@media (max-width: 1515px ) {
    .home .cover .right .card {
        width: 400px;
        height: 250px;
        top: 20%;
    }


    .home .cover .left h1 {
        font-size: 2.5rem;
    }

    .home .cover .left .innovation-text {
        font-size: 5rem;
    }

    .home .cover .left h2 {
        font-size: 1.5rem;
    }

    .home .cover .left p {
        font-size: 1rem;
    }

    .home .cover .left button {
        font-size: 1rem;
        padding: 15px 20px;
        width: 200px;
    }

    .home .cover .left .check-icon {
        height: 20px;
        width: 20px;
    }
}   

@media (max-width: 1370px) {
    .home .cover .right .software-card {
        right: -24%
    }
}

@media (max-width: 1200px) {
    .home .cover .right .hardware-card {
        top: 15%;
        left: 10%
    }

    .home .cover .right .software-card {
        right: -12%
    }
}

@media (max-width: 1150px) {
    .home {
        padding-top: 60px;
        height: auto
    }

    .home .cover {
        justify-content: center;
        height: auto;
        gap: 0px;
        flex-wrap: wrap;
        align-items: center;
    }

    .home .cover .left,
    .home .cover .right {
        width: 100%;
    }

    .home .cover .left {
        align-items: center;
        padding: 0px;
        padding-top: 30px;
        padding-bottom: 0px;
        box-shadow: -7px 55px 37px -22px rgba(255,255,255,0.75);
        -webkit-box-shadow: -7px 55px 37px -22px rgba(255,255,255,0.75);
        -moz-box-shadow: -7px 55px 37px -22px rgba(255,255,255,0.75);
    }

    .home .cover .left h1 {
        text-align: center;
    }

    .home .cover .right {
        height: 550px;
        position: relative;
        top: 100px;
    }

    .home .cover .right .software-card {
        top: -12%;
        left: 50%;
    }
}

@media (max-width: 930px) {
    .home .cover .right {
        height: 600px;
        width: 100%;
    }

    .home .cover .right .hardware-card {
        top: 1%
    }

    .home .cover .right .software-card {
        top: 5%;
    }
}

@media (max-width: 880px) {
    .home .cover .right .software-card {
        left: 40%;
        top: 5%;
    }
}

@media (max-width: 755px) {
    .home .cover .right .hardware-card {
        rotate: -10deg;
    }

    .home .cover .right .software-card {
        rotate: 10deg;
        left: 30%;
        top: 10%;
    }
}

@media (max-width: 755px) {

    .home .cover .right {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 40px;
        justify-content: center;
        align-items: center;
    }

    .home .cover .right .hardware-card {
        rotate: 0deg;
        position: static;
    }

    .home .cover .right .software-card {
        rotate: 0deg;
        position: static;
    }
}

@media (max-width: 490px) {
    .home .cover .left h1 {
        font-size: 2rem
    }

    .home .cover .left .innovation-text {
        font-size: 4rem;
    }

    .home .cover .left h2 {
        font-size: 21px;
    }
}

@media (max-width: 440px) {
    .home .cover .right .card {
       scale: 0.9; 
    }
}

@media (max-width: 400px) {

    .home .cover .right {
        gap: 10px
    }

    .home .cover .right .card {
        scale: 0.7
    }

    .home .cover .left h1 {
        font-size: 26px;
    }

    .home .cover .left .innovation-text {
        font-size: 56px;
    }
    
    .home .cover .left h2 {
        font-size: 18px;
    }

    .home .cover .left p {
        font-size: 14px;
    }
}