.faq-section {
    max-width: calc(1344px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    user-select: none;
    padding-top: 120px;
    margin-bottom: 120px;
}

.faq-section .faq-header {
    width: 100%;
}

.faq-section .faq-title {
    position: relative;
    font-size: 36px;
    margin: 0;
}

.faq-section .faq-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.faq-section .faq-container .faq-left,
.faq-section .faq-container .faq-right {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 40%;
    height: auto
}

.faq-section .faq-container .faq-left .faq-text,
.faq-section .faq-container .faq-right .faq-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
}

.faq-section .faq-container .faq-left .faq-text .faq-card,
.faq-section .faq-container .faq-right .faq-text .faq-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 240px;
    gap: 100px
}

.faq-section .faq-container .faq-left .faq-text  .faq-card .circle,
.faq-section .faq-container .faq-right .faq-text .faq-card  .circle{
    font-weight: 700;
    background-color: #333333;
    color: white;
    text-align: center;
    font-size: 20px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq-section .faq-container .faq-left .faq-text  .faq-card .circle svg,
.faq-section .faq-container .faq-right .faq-text .faq-card  .circle svg{
    width: 100%;
    height: 100%;
}

.faq-section .faq-container .faq-left .faq-text .faq-card .icon-container,
.faq-section .faq-container .faq-right .faq-text .faq-card .icon-container {
    height: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
}

.faq-section .faq-container .faq-left .faq-text .faq-card  .line,
.faq-section .faq-container .faq-right .faq-text .faq-card  .line {
    
    height: 50%;
    border: 2px solid #428bca;
    border-radius: 25px;
} 

.faq-section .faq-container .faq-left .faq-text .faq-card  .no-line,
.faq-section .faq-container .faq-right .faq-text .faq-card  .no-line {
    display: none;
} 

.faq-section .faq-container .faq-left .faq-text .faq-card  .text-container,
.faq-section .faq-container .faq-right .faq-text  .faq-card .text-container {
    height: 240px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.faq-section .faq-container .faq-left .faq-text  .faq-card .text-container h2,
.faq-section .faq-container .faq-right .faq-text .faq-card  .text-container h2{
    margin: 0;
    font-size: 21px;
}

/* MEDIA QUERIES */
/* easier search term ( faq-media ) */

@media (max-width: 1600px) {
    .faq-section {
        width: calc(100% - 240px);
    }

    .faq-section .faq-container .faq-left, 
    .faq-section .faq-container .faq-right {
        width: 45%;
    }
}

@media (max-width: 1220px) {
    .faq-section .faq-container .faq-left .faq-text .faq-card,
    .faq-section .faq-container .faq-right .faq-text .faq-card { 
        height: 300px
    }
}

@media (max-width: 1150px ) {
   .faq-section {
        width: calc(100% - 120px);
    } 
}

@media (max-width: 1000px) {
    .faq-section {
        margin-bottom: 30px;
    }    

    .faq-section .faq-container {
        flex-direction: column;
    }

    .faq-section .faq-container .faq-left, 
    .faq-section .faq-container .faq-right {
        width: calc(100%);
        justify-content: flex-start;
    }

    .faq-section .faq-container .faq-left .faq-text .faq-card,
    .faq-section .faq-container .faq-right .faq-text .faq-card { 
        height: 150px
    }

    .faq-section .faq-container .faq-left .faq-text .faq-card .text-container, 
    .faq-section .faq-container .faq-right .faq-text .faq-card .text-container {
        max-width: 100%;
        height: 100%;
    }

    .faq-section .faq-container .faq-left .faq-text .faq-card .icon-container, 
    .faq-section .faq-container .faq-right .faq-text .faq-card .icon-container {
        gap: 0;
    }

    .faq-section .faq-container .faq-right {
        margin: 0;
    }
}

@media (max-width: 820px) {
   .faq-section {
        width: calc(100% - 60px);
    }  
}

@media (max-width: 720px) {
    .faq-section .faq-container .faq-left .faq-text .faq-card,
    .faq-section .faq-container .faq-right .faq-text .faq-card { 
        height: 250px;
        gap: 30px
    }
    
    .faq-section .faq-container .faq-left .faq-text .faq-card .icon-container,
    .faq-section .faq-container .faq-right .faq-text .faq-card .icon-container { 
        gap: 30px;
    }

}
