.services {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.services #wave {
    background-color: transparent;
    z-index: 1;
    position: relative;
    top: 5px
}

.services-content {
    z-index: 2;
    background-color: #428bca;
    width: 100%;
    height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow:0px -20px 20px 5px white ; */
    user-select: none;
    
}

.services .header {
    max-width: 1344px;
    width: 100%;
    margin-bottom: 40px;
    color: white;
}

.services .header .title {
    font-size: 38px;
    font-weight: 800;
    color: white;
}

.services .services-container {
    display: flex;
    flex-direction: row;
    max-width: 1344px;
    width: 100%;
    gap: 40px;
    flex-wrap: wrap;
}

.services .services-container .service-card {
    width: 300px;
}

.services .services-container .service-card .top {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.services .services-container .service-card .top .circle {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 21px;
    font-weight: 700;
    background-color: #333333;
    border-radius: 50%;
}

.services .services-container .service-card .top .circle .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.services .services-container .service-card .top .circle .icon svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50% !important;

}

.services .services-container .service-card .top .line {
    display: flex;
    border-radius: 50px;
    border: 2px solid #f9d76f;
    margin: 0;
    margin-left: 25px;
    width: calc(100% - 100px);
}

.services .services-container .service-card .text h3 {
    font-size: 21px;
    color: white;
    margin-bottom: 0px;
}


.services .services-container .service-card .text p {
    font-size: 14px;
    color: white;
}

/* THE SERVICES ANIMATION */

.services .animation-activated .service-card .top .line {
    animation: line-extend 0.5s forwards;
}

.services .animation-activated .service-card .top .circle {
    animation: pulse 0.5s forwards;
}

.services .animation-activated .service-card {
  opacity: 0;
  animation: fadeInUp 0.5s forwards; /* Animation lasts for 0.5s */
}

@keyframes pulse {
    0% {
        scale: 1;
    }   
    30% {
        scale: 1.1
    } 
    40% {
        scale: 1.25
    }
    100% {
        scale: 1
    }
}

@keyframes line-extend {
    0% {
        width: calc(1% - 100px)
    }
    100% {
        width: calc(100% - 100px)
    }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* MEDIA QUERIES */
/* QuickSearch = ( service-media ) */

@media (max-width: 1600px) {
    .services .header {
        width: calc(100% - 240px)
    }
   
    .services .services-container {
        width: calc(100% - 240px);
    }
}

@media (max-width: 1559px) {
    .services .services-container {
        justify-content: space-between;
    }
}

@media (max-width: 1150px) {
    .services .header {
        width: calc(100% - 120px)
    }
   
    .services .services-container {
        width: calc(100% - 120px);
    }

}

@media (max-width: 1099px) {
    .services .services-container .service-card {
        width: 400px
    }
}

@media (max-width: 960px) {
   .services .services-container .service-card {
        width: 30%
    } 
}

@media (max-width: 920px) {
   .services .services-container .service-card {
        width: 45%
    } 

    .services-content {
        height: auto
    }
}

@media (max-width: 820px) {
   .services .header {
        width: calc(100% - 30px)
    }
   
    .services .services-container {
        width: calc(100% - 30px);
    } 
}

@media (max-width: 625px) {

    .services .services-container .service-card {
        width: 100%
    }
    
}