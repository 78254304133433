.navbar {
  width: calc(100% - 240px);
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 120px;
  max-height: 61px;
  background-color: white;
  color: white;
  border-bottom: 1px solid #ccc;
  user-select: none;
  z-index: 10;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 130px;
  height: 52.21px;
}

.navbar-links {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.navbar-links li {
  color: black;
  text-decoration: none;
  margin: 0 20px;
  list-style-type: none;
}

.navbar-links li:last-child {
  margin-right: 0;
}

.navbar-links li:hover {
  color: #428bca;
}

/* MOBILE NAVBAR STYLING */
.mobile-navbar {
  width: calc(100% - 240px);
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 120px;
  max-height: 61px;
  background-color: white;
  color: white;
  border-bottom: 1px solid #ccc;
  user-select: none;
  z-index: 3;
}

.mobile-navbar-links {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.mobile-navbar-links .menu-icon {
  position: relative;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 30px;
  color: black;
}

.mobile-navbar-links .links {
  list-style-type: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 56px;
  right: 0px;
  width: 120px;
  height: 200px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 24px;
  background-color: white;
  padding: 15px;
}

.mobile-navbar-links .links a {
  color: black;
  text-decoration: none;
  width: calc(100% - 10px);
  padding: 5px;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 12px;
}

#nav-icon3 {
  width: 100%;
  height: 100%;
  position: relative;
  /* margin: 50px auto; */
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #333333;
  border-radius: 25px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 10px;
}

#nav-icon3 span:nth-child(4) {
  top: 20px;
}

#nav-icon3.open span:nth-child(1) {
  top: 20px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}


/* MEDIA QUERIES */
/* easier search term ( nav-media ) */

@media (max-width: 1150px) {

  .mobile-navbar,
  .navbar {
    width: calc(100% - 120px);
    padding: 10px 60px;
  }
}

@media (max-width: 820px) {

  .mobile-navbar,
  .navbar {
    padding: 10px 15px;
    width: calc(100% - 30px)
  }
}
