.footer {
    display: flex;
    flex-direction: column;
    background-color: white;
    color : #333333;
    width: calc(100% );
    height: auto;
}

.footer #wave {
    width: 100%;
    height: auto;
    position: relative;
    background-color: white;
    bottom: -5px;
    z-index: 1;
}

.footer .footer-container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    width: 100%;
    height: 100%;
    background-color: #428bca;
    padding: 20px 0px 20px 0px;
}

.footer .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 672px;
}

.footer .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 672px;
    height: auto;
    gap: 20px;
}

.footer .footer-content .socials {
    width: 332px;
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer .footer-content .socials .icon-container {
    display: flex;
    padding: 10px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid gray;
    color: #333333;
    cursor: pointer;
}

.footer .footer-content .details-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.footer .footer-content .details-container .bottom {
    width: 60%;
    display: flex;
    justify-content: center;
    gap: 10px
}

.footer .footer-content .details-container .details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: white;
    padding: 2px 6px;
    border-radius: 25px;
}

.footer .footer-content .details-container .details .detail-icon {
    height: 15px;
}

.footer .footer-content .details-container p{
    font-size: 12px;
    text-wrap: nowrap;
    margin: 0;
}

.footer .footer-content a,
.footer .footer-content h3 {
    color : #333333;
    
    text-decoration: none;
}

.footer .footer-content a {
    font-size: 1rem;
    font-weight: 400;
    margin: 10px 5px;
}

.footer .footer-content h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 5px 5px;
    margin-bottom: 20px;
}

.footer .logo {
    width: 188px;
}

.footer .copyright-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    
    background-color: white;
    border-top: 1px solid #ccc;
}

.footer .copyright-container p {
    font-size: 0.8rem;
    font-weight: 300;
    color : #333333;
    margin: 10px 5px;
}

@media (max-width: 820px) {

    .footer .logo-container,
    .footer .footer-content {
        width: 100%;
    } 
}